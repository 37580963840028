import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Disc, X, Circle } from "react-feather";
import classnames from "classnames";
import maxlogodash from "../../../../assets/max-learn-img/MaxLearn_Logo.png";
import { getCookie } from "../../../../services/CookieManager";

class SidebarHeader extends Component {
  render() {
    let {
      toggleSidebarMenu,
      activeTheme,
      collapsed,
      toggle,
      sidebarVisibility,
      menuShadow,
    } = this.props;
    return (
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <NavLink to="/creator-dashboard" className="navbar-brand">
              {/* <div className="brand-logo" /> */}
              {/* <h2 className="brand-text mb-0">MaxLearn</h2> */}
              {console.log(getCookie("Companyimage"), "hhh")}
              <img
                src={
                  getCookie("Companyimage") &&
                  getCookie("Companyimage") !== "undefined"
                    ? getCookie("Companyimage")
                    : maxlogodash
                }
                className="dash_logoallnew"
              />
            </NavLink>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false,
          })}
        />
      </div>
    );
  }
}

export default SidebarHeader;
