import React, { useState, useEffect } from "react";
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import profileimagedummy from "../../../assets/max-learn-img/default-profile.png";
import { useHistory, useLocation } from "react-router";
import {
  clearAllCookies,
  clearCookie,
  getCookie,
} from "../../../services/CookieManager";
import { decryptData } from "../../../services/LocalstorageManager";
import { useTranslation } from "react-i18next";

const NavbarUser = (props, profilepic) => {
  const { t } = useTranslation();
  const secretKey = "###"; 
  const history = useHistory();
  const logoutClick = () => {
    clearCookie("applicationId");
    clearCookie("token");
    clearCookie("fullname");
    clearCookie("user-role");
    clearCookie("learnerID");
    clearCookie("emailID");
    clearCookie("userid");
    clearAllCookies();
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("applicationId");
    localStorage.removeItem("fullname");
    localStorage.removeItem("userid");
    localStorage.removeItem("learnerID");
  };

  const profileredirect = () => {
    history.push({
      pathname: `/profileview`,
      state: [getCookie("userid")],
    });
  };

  const decryptedRoleString = decryptData(localStorage.getItem("role-title"), secretKey);
  console.log(decryptedRoleString,'decryptedRoleString')
//var parsedDataFeatures = JSON.parse(decryptedRoleString);

  const UserDropdown = () => {
    return (
      <DropdownMenu right>
        <DropdownItem tag="a" className="tabs_drop" onClick={profileredirect}>
          <span className="align-middle">{t("DASHBOARD.EDIT_PROFILE")}</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          tag="a"
          className="tabs_drop"
          href="/"
          onClick={logoutClick}
        >
          <span className="align-middle">{t("DASHBOARD.LOG_OUT")}</span>
        </DropdownItem>
      </DropdownMenu>
    );
  };
  return (
    <>
      <div>
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span
                  className="user-name text-bold-600 user_login txt_userns role_strta"
                  title={getCookie("fullname")}
                >
                  {props.userName}
                </span>
                <span className="role_txtc">
                  {decryptedRoleString}
                </span>
              </div>
              <span data-tour="user">
                <img
                  src={
                    localStorage.getItem("profilepic") !== null &&
                    localStorage.getItem("profilepic") !== "undefined"
                      ? localStorage.getItem("profilepic")
                      : profileimagedummy
                  }
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                />
              </span>
            </DropdownToggle>
            <UserDropdown />
          </UncontrolledDropdown>
        </ul>
      </div>
    </>
  );
};
export default NavbarUser;
